<script setup>
import { ref, computed, watch } from 'vue';

const props = defineProps(['filter', 'filtersStore']);

const selectedSlug = ref(null);
const isMore = ref(false);
const showData = ref([]);

const updateValue = (filter, row) => {
  if (row.slug === selectedSlug.value) {
    selectedSlug.value = null;
    props.filtersStore.updateValue(filter, null);
  } else {
    selectedSlug.value = row.slug;
    props.filtersStore.updateValue(filter, row);
  }
};

const dataSource = computed(() => props.filter.data_source);

watch(dataSource, () => {
  if (isMore.value) {
    showData.value = dataSource.value;
  } else {
    showData.value = dataSource.value.slice(0, 5);
  }
});

const changeIsMore = () => {
  isMore.value = !isMore.value;
  if (isMore.value) {
    showData.value = dataSource.value;
  } else {
    showData.value = dataSource.value.slice(0, 5);
  }
};

const initialSlug = props.filtersStore.selected_filters[props.filter.slug]?.value;
if (initialSlug) {
  selectedSlug.value = initialSlug;
  updateValue(props.filter, { slug: initialSlug });
}

showData.value = dataSource.value.slice(0, 5);
</script>

<template>
    <div class="px-3 mt-3">
      {{ filter.name }}
    </div>

    <div class="p-3 text-base mt-1 pb-3 border-b border-b-slate-200">
      <div class="flex flex-wrap gap-x-1 gap-y-2 ">
        <div v-for="item in showData" class="">
          <w-button
         
            @click="updateValue(filter, item)"
            :class="['px-1 py-1 text-gray-500  ring-1 ring-inset ring-current ',item.slug == selectedSlug ?'bg-indigo-800 text-white':'']"
          >
            <span class="text-sm">{{ item.label_value || item.value }}</span>
          </w-button>
        </div>
      </div>
      <div v-if="showData.length != filter.data_source.length || (showData.length == filter.data_source.length && isMore)" class="mt-3">
        <template v-if="isMore">
          <button @click="changeIsMore" class="text-indigo-900 text-sm underline">Less</button>
        </template>
        <template v-else>
          <button @click="changeIsMore" class="text-indigo-900 text-sm underline">More</button>
        </template>
      </div>
    </div>
</template>